import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import { SiteWrapper, SiteContent, TeamSiedeBar, TeamSiteContent, white, FlexContainerRow } from "../components/styles";
import {motion} from "framer-motion";
import { useQuery } from "@apollo/react-hooks";
import {gql} from 'apollo-boost';
import PersonSEO from "../components/person-seo";

const TEAMMITGLIED = gql`
query Teammitglied($id: ID)
{
  teammitglieder(where: {id: $id}) {
    id
    vorname
    nachname
    beschreibung {
      raw
      html
    }
    eMail
    telefon
    strasse
    ort
    portrait {
      fileName
      url
    }
    leistungen {
      id
      titel
    }
    kurse {
      id
      titel
    }
  }
}
`
const teamseite = {
    ["h1"]: {margiBottom: "1em"}
}


const TeamTemplate = ({pageContext}) => {
  const { loading, error, data } = useQuery(TEAMMITGLIED, {variables: {id: pageContext.id}})
  function slugify(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
  let image = pageContext.vorname + ' ' + pageContext.nachname 
  if (error) return <Layout layoutImage={image}></Layout> 
  else if(loading) return <Layout layoutImage={image}></Layout>
  else if (data) {
  let teammitglied = data.teammitglieder[0]
  return (
    <Layout layoutImage={image}>
      <SiteWrapper> 

        <PersonSEO 
              title="Team" 
              type="Person" 
              name={pageContext.vorname + " " + pageContext.nachname} 
              imageUrl={teammitglied.portrait.url}
              siteUrl={`/team/${slugify(pageContext.vorname)}-${slugify(pageContext.nachname)}`}
              email={teammitglied.eMail}
              telefon={pageContext.telefon}
            />
          
        <SiteContent>
        <motion.h1>{pageContext.vorname} {pageContext.nachname}</motion.h1>
        <FlexContainerRow>
        <TeamSiteContent>
        
        
         <div> 
            {teammitglied.telefon && <p>Tel.: {teammitglied.telefon}</p>}
            {teammitglied.eMail && <p>E-Mail: <a href={"mailto:" + teammitglied.eMail}>{teammitglied.eMail} </a>  </p>}
            {teammitglied.strasse && <p>{teammitglied.strasse + ", "} {teammitglied.ort} </p>}
          <div dangerouslySetInnerHTML={{__html: teammitglied.beschreibung.html}} css={{marginTop: "2em"}} />
        </div>
        </TeamSiteContent>
            <TeamSiedeBar>
                <img  css={{border: "1px solid " + white, width: "75%", height: "auto", padding: 0, marginLeft: "12.5%", maxWidth: "12em" }} src={teammitglied.portrait.url} /> 
                {(teammitglied.leistungen[0]) ? <h4>Leistungen</h4> : null} 
                <ul>{teammitglied.leistungen.map((leistung, index) => {return(<li key={index}><Link to={"/leistungen#" + leistung.id} >{leistung.titel}</Link> </li> )}   )}</ul>
                {(teammitglied.kurse[0]) ? <h4>Kurse</h4> : null}   
                <ul>{teammitglied.kurse.map((kurs, index) => {return(<li key={index}><Link to={"/kurse-und-anmeldung#" + kurs.id} >{kurs.titel}</Link> </li> )}   )}</ul>
            </TeamSiedeBar>
        </FlexContainerRow>
  
        </SiteContent>
      </SiteWrapper>
      </Layout>
)}
};

export default TeamTemplate;
